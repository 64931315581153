<template>
    <div>
      <page-title :title="`Banks`">
        <template slot="button" >
          <router-link
            :to="{ name: 'banks.create' }"
            class="btn btn-primary btn-sm float-right" style="color:white !important"
          >
            <i class="lni-plus mr-2"></i> Add Bank
          </router-link>
        </template>
      </page-title>
  
      <div class="card">
        <div class="card-body">
          <table class="table  table-striped bg-white">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email </th>
                <th>Unique ID </th>
                <th>Actions </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(bank, i) in banks" :key="`bank-${i}`">
                <td>{{ i + 1 }}</td>
                <td>{{ bank.name }}</td>
                <td>{{ bank.email_address }}</td>
                <td>{{ bank.unique_id}}</td>

                <td>
                  
                  <router-link :to="{ name: 'banks.edit', params: { id: bank.unique_id } }" class="btn btn-sm btn-warning mr-2" style="color:white !important"><i class="lni-pencil mr-2"></i> Edit</router-link>

                 <a href="#" @click.prevent="destroy(bank)" class="btn btn-sm btn-danger mr-2" style="color:white !important"><i class="lni-trash mr-2"></i> Delete</a>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import $ from "jquery";
  
  export default {
    mounted() {
      this.fetch();
    },
  
    data() {
      return {
        banks: [],
      };
    },
  
    methods: {
      fetch() {
        this.$loader.start();
        this.$axios
          .get("/api/v1/dashboard/banks")
          .then((response) => {
            this.banks = response.data;
            setTimeout(() => {
              this.$loader.stop();
              $("table").DataTable();
            });
          }, 1000)
          .catch((error) => {
            this.$http(error.response);
          });
      },
      destroy(bank) {
        
        this.$axios.delete(`/api/v1/dashboard/banks/${bank.id}`).then(() => {
          this.$loader.start();

          location.reload(); // reload the page

        }).catch(error => {
          console.log(error)
        })
      },
    },
  };
  </script>
  